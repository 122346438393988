import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "../HomePage/HomePage";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import ServiceSingle from "../../components/ServiceSingle";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/services" element={<ServiceSection />} />
          <Route path="/sluzby/:serviceId" element={<ServiceSingle />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
