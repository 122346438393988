import React, { Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import { Element } from "react-scroll";
import Loader from "../../Loader/loader";

// Lazy loading components
const Navbar = lazy(() => import("../../components/Navbar/Navbar"));
const Hero = lazy(() => import("../../components/hero/hero"));
const About = lazy(() => import("../../components/about/about"));
const CalculatorSection = lazy(() =>
  import("../../components/CalculatorSection/CalculatorSection")
);
const MarqueeComponent = lazy(() => import("../../components/marque/marque"));
const ServiceSection = lazy(() =>
  import("../../components/ServiceSection/ServiceSection")
);
const BlogSection = lazy(() =>
  import("../../components/BlogSection/BlogSection")
);
const Testimonial = lazy(() =>
  import("../../components/Testimonial/Testimonial")
);
const Pricing = lazy(() => import("../../components/Schedule"));
const ContactArea = lazy(() => import("../../components/ContactArea"));
const Footer = lazy(() => import("../../components/footer/Footer"));
const BackToTop = lazy(() => import("../../components/backToTop/backToTop"));

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Milos Kolar - Hypoteční Specialista | Hradec Králové</title>
        <meta
          name="description"
          content="Milos Kolar je hypoteční specialista v Hradci Králové. Pomáháme najít nejlepší hypoteční řešení přizpůsobené vašim potřebám. Kontaktujte mě a zjistěte, jak Vám můžu pomoci s Vaší hypotékou."
        />
      </Helmet>
      <Suspense fallback={<Loader />}>
        <Navbar />
        <Element name="home">
          <Hero />
        </Element>
        <Element name="about">
          <About />
        </Element>
        <Element name="calculators">
          <CalculatorSection />
        </Element>
        <Element name="service">
          <MarqueeComponent />
          <ServiceSection />
        </Element>
        <Element name="blog">
          <BlogSection />
        </Element>
        <Testimonial />
        <Pricing />
        <Element name="contact">
          <ContactArea />
        </Element>
        <Footer />
        <BackToTop />
      </Suspense>
    </>
  );
};

export default HomePage;
