import React from "react";
import Marquee from "react-marquee-slider";
import { useLocation } from "react-router-dom";
import burinka from "../../images/companies/burinka.webp";
import csob from "../../images/companies/csob.webp";
import hypobank from "../../images/companies/hypobank.webp";
import modrap from "../../images/companies/modrap.webp";
import kb from "../../images/companies/kb.webp";
import mbank from "../../images/companies/mbank.webp";
import raiff from "../../images/companies/raiff.webp";
import sporitelna from "../../images/companies/sporitelna.webp";
import unicredit from "../../images/companies/unicredit.webp";

import alianz from "../../images/companies/insurance/alianz.webp";
import axa from "../../images/companies/insurance/axa.webp";
import cpp from "../../images/companies/insurance/cpp.webp";
import csobPoji from "../../images/companies/insurance/csobpoji.webp";
import direct from "../../images/companies/insurance/direct.webp";
import kbPoji from "../../images/companies/insurance/kb.webp";
import koop from "../../images/companies/insurance/koop.webp";
import metlife from "../../images/companies/insurance/metlife.webp";
import nn from "../../images/companies/insurance/nn.webp";
import pillow from "../../images/companies/insurance/pillow.webp";
import slavia from "../../images/companies/insurance/slavia.webp";
import uni from "../../images/companies/insurance/uni.webp";

const MarqueeComponent = () => {
  const location = useLocation();
  const isInsurancePage = location.pathname === "/sluzby/pojisteni";

  const companyImages = [
    burinka,
    csob,
    hypobank,
    modrap,
    kb,
    mbank,
    raiff,
    sporitelna,
    unicredit,
  ];

  const insuranceImages = [
    alianz,
    axa,
    cpp,
    csobPoji,
    direct,
    kbPoji,
    koop,
    metlife,
    nn,
    pillow,
    slavia,
    uni,
  ];

  const imagesToDisplay = isInsurancePage ? insuranceImages : companyImages;

  return (
    <div className="digital-marque-sec">
      <div className="container">
        <div className="digital-marque">
          <Marquee velocity={50}>
            {imagesToDisplay.map((image, index) => (
              <img
                key={index}
                src={image}
                width={"200px"}
                alt={`Logo společnosti ${index}`}
              />
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default MarqueeComponent;
