import React from "react";
import ContactForm from "../ContactFrom/ContactForm";

const ContactArea = (props) => {
  return (
    <section className="tp-contact-form-area section-padding">
      <div className="container">
        <div className="tp-contact-form-wrap">
          <div className="tp-section-title-light">
            <h2>Pojďme spolupracovat!</h2>
            <p className="p-text-light">
              Vaše emailová adresa nebude nikde zveřejňována. Veškerá pole je
              nutné vyplnit.
            </p>
          </div>
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default ContactArea;
