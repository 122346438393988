import React from "react";
import { useNavigate } from "react-router-dom";
import Services from "../../api/service";

const ServiceSection = () => {
  const navigate = useNavigate();

  const handleClickOpen = (serviceId) => {
    navigate(`/sluzby/${serviceId}`);
  };

  return (
    <div className="tp-service-area section-padding">
      <div className="container">
        <div className="tp-section-title-light">
          <span>Co pro Vás mohu udělat</span>
          <h2>Nejžádanější služby</h2>
        </div>
        <div className="tp-service-wrap">
          <div className="row align-items-center">
            {Services.slice(0, 4).map((service) => (
              <div className="col col-lg-3 col-md-6 col-12" key={service.Id}>
                <div
                  className="tp-service-item"
                  onClick={() => handleClickOpen(service.linkName)}
                >
                  <i className={`fi ${service.icon}`}></i>
                  <h2>{service.sTitle}</h2>

                  <p className="p-text-light">
                    {service.description.slice(0, 175)}
                    {service.description.length > 175 ? "..." : ""}
                  </p>
                  <button className="read-more">
                    <i className="fi flaticon-right-arrow"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
