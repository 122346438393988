import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import MobileMenu from "../MobileMenu/MobileMenu";
import Logo from "../../images/logo.png";

const Header = (props) => {
  const [menuActive, setMenuState] = useState(false);
  const location = useLocation();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <header id="header" className={props.topbarNone}>
      <div className={`tp-site-header navigation`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              {location.pathname !== "/" ? (
                <div className="col-12 text-center">
                  <RouterLink
                    to="/"
                    className="theme-btn"
                    onClick={ClickHandler}
                  >
                    Domů
                  </RouterLink>
                </div>
              ) : (
                <>
                  <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                    <MobileMenu />
                  </div>
                  <div className="col-lg-3 col-md-6 col-6">
                    <div className="navbar-header">
                      <RouterLink to="/" onClick={ClickHandler}>
                        <img src={Logo} alt="" width={"40px"} />
                      </RouterLink>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-1 col-1">
                    <div
                      id="navbar"
                      className="collapse navbar-collapse navigation-holder"
                    >
                      <button
                        className="menu-close"
                        onClick={() => setMenuState(false)}
                      >
                        <i className="ti-close"></i>
                      </button>
                      <ul className="nav navbar-nav mb-2 mb-lg-0">
                        <li>
                          <LinkNavItem to="home" children="Domů" />
                        </li>
                        <li>
                          <LinkNavItem to="about" children="O mně" />
                        </li>
                        <li>
                          <LinkNavItem to="calculators" children="Kalkulačky" />
                        </li>
                        <li>
                          <LinkNavItem to="service" children="Služby" />
                        </li>
                        <li>
                          <LinkNavItem to="blog" children="Blog" />
                        </li>
                        <li>
                          <LinkNavItem to="contact" children="Kontakt" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-2 col-2">
                    <div className="header-right">
                      <div className="header-search-form-wrapper">
                        <div className="cart-search-contact">
                          <button
                            className="search-toggle-btn"
                            onClick={() => setMenuState(!menuActive)}
                          >
                            <i
                              className={`ti-search ${
                                menuActive ? "ti-close" : "ti-search"
                              }`}
                            ></i>
                          </button>
                          <div
                            className={`header-search-form ${
                              menuActive ? "header-search-content-toggle" : ""
                            }`}
                          >
                            <form onSubmit={SubmitHandler}>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Napište co hledáte..."
                                />
                                <button type="submit">
                                  <i className="fi ti-search"></i>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const LinkNavItem = ({ to, children }) => {
  // Use React Scroll for smooth scrolling on the homepage, otherwise, render as normal links
  if (useLocation().pathname === "/") {
    return (
      <Link
        activeClass="active"
        to={to}
        spy={true}
        smooth={true}
        duration={500}
        offset={-95}
      >
        {children}
      </Link>
    );
  } else {
    return <RouterLink to={`/${to}`}>{children}</RouterLink>;
  }
};

export default Header;
