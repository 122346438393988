import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Services from "../../api/service";
import ContactArea from "../../components/ContactArea";
import MarqueeComponent from "../../components/marque/marque";
import Navbar from "../../components/Navbar/Navbar";

const ServiceNotFound = () => (
  <div className="tp-service-area section-padding">
    <div className="container">
      <div>Service not found</div>
    </div>
  </div>
);

const SectionTitle = ({ title, light }) => (
  <div className="tp-service-single-item">
    <div className={light ? "tp-section-title-light" : "tp-section-title"}>
      <h2>{title}</h2>
    </div>
  </div>
);

const ServiceDescription = ({ descriptions, light }) => (
  <div className="tp-service-description">
    {Array.isArray(descriptions) ? (
      descriptions.map((description, index) => (
        <p key={index} className={light ? "p-text-light" : ""}>
          {description}
        </p>
      ))
    ) : (
      <p className={light ? "p-text-light" : ""}>{descriptions}</p>
    )}
  </div>
);

const AdditionalImages = ({ images }) => (
  <div className="row mt-4">
    {images.map((image, index) => (
      <div key={index} className="col-12">
        <div className="tf-about-img">
          <img src={image.src} alt={image.alt} />
        </div>
      </div>
    ))}
  </div>
);

const MortgageStepTile = ({ step }) => (
  <div className="col-md-4">
    <div className="tp-mortgage-step-item">
      <img
        src={step.icon}
        alt={step.name}
        style={{ maxWidth: "50px", margin: "0 auto" }}
      />
      <h2>{step.name}</h2>
      <p>{step.description}</p>
    </div>
  </div>
);

const ServiceSingle = () => {
  const { serviceId } = useParams();
  const service = Services.find((s) => s.linkName === serviceId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!service) {
    return <ServiceNotFound />;
  }

  const additionalImages = [{ src: service.image, alt: "Additional detail 1" }];

  return (
    <div>
      <Navbar />
      <div className="tp-service-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <SectionTitle title={service.sTitle} light={true} />
              <ServiceDescription
                descriptions={service.description}
                light={true}
              />
            </div>
            <div className="col-lg-6">
              <AdditionalImages images={additionalImages} />
            </div>
          </div>
        </div>
      </div>
      <MarqueeComponent />

      {service.sTitle2 && (
        <div className="tf-about-section section-padding">
          <div className="container">
            <SectionTitle title={service.sTitle2} light={false} />
            <ServiceDescription
              descriptions={service.description2}
              light={false}
            />
          </div>
        </div>
      )}
      {service.serviceSteps && (
        <div className="tp-service-area section-padding">
          <div className="container">
            <div className="tp-service-wrap">
              <div className="row align-items-center">
                {service.serviceSteps.map((step, idx) => (
                  <MortgageStepTile key={idx} step={step} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {service.description3 && (
        <div className="tf-about-section section-padding">
          <div className="container">
            <SectionTitle title={service.sTitle3} light={false} />
            <ServiceDescription
              descriptions={service.description3}
              light={false}
            />
          </div>
        </div>
      )}
      {service.description4 && (
        <div className="tf-service-area section-padding">
          <div className="container">
            <SectionTitle title={service.sTitle4} light={true} />
            <ServiceDescription
              descriptions={service.description4}
              light={true}
            />
          </div>
        </div>
      )}
      {service.description5 && (
        <div className="tf-about-section section-padding">
          <div className="container">
            <SectionTitle title={service.sTitle5} light={false} />
            <ServiceDescription
              descriptions={service.description5}
              light={false}
            />
          </div>
        </div>
      )}

      <ContactArea />
    </div>
  );
};

export default ServiceSingle;
