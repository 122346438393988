import sgImgS1 from "../images/service-single/mortgageSteps/mortgage_bg.webp";
import sgImgS2 from "../images/service-single/compulsorySteps/compulsory_bg.webp";
import sgImgS3 from "../images/service-single/insuranceSteps/insurance_bg.webp";
import sgImgS4 from "../images/service-single/market/img-1.webp";

import msImgS1 from "../images/service-single/mortgageSteps/img-1.webp";
import msImgS2 from "../images/service-single/mortgageSteps/img-2.webp";
import msImgS3 from "../images/service-single/mortgageSteps/img-3.webp";
import msImgS4 from "../images/service-single/mortgageSteps/img-4.webp";
import msImgS5 from "../images/service-single/mortgageSteps/img-5.webp";
import msImgS6 from "../images/service-single/mortgageSteps/img-6.webp";
import msImgS7 from "../images/service-single/mortgageSteps/img-7.webp";
import msImgS8 from "../images/service-single/mortgageSteps/img-8.webp";
import msImgS9 from "../images/service-single/mortgageSteps/img-9.webp";

import isImgS1 from "../images/service-single/insuranceSteps/img-1.webp";
import isImgS2 from "../images/service-single/insuranceSteps/img-2.webp";
import isImgS3 from "../images/service-single/insuranceSteps/img-3.webp";
import isImgS4 from "../images/service-single/insuranceSteps/img-4.webp";
import isImgS5 from "../images/service-single/insuranceSteps/img-5.webp";
import isImgS6 from "../images/service-single/insuranceSteps/img-6.webp";

import csImgS1 from "../images/service-single/compulsorySteps/img-1.webp";
import csImgS2 from "../images/service-single/compulsorySteps/img-2.webp";
import csImgS3 from "../images/service-single/compulsorySteps/img-3.webp";
import csImgS4 from "../images/service-single/compulsorySteps/img-5.webp";
import csImgS5 from "../images/service-single/compulsorySteps/img-4.webp";

const Services = [
  {
    Id: "1",
    image: sgImgS1,
    sTitle: "Hypotéky",
    linkName: "hypoteky",
    description:
      "Jak získat hypotéku? Sjednání hypotéky může být zdánlivě komplikovaný proces, avšak ve skutečnosti je to o řádění několika klíčových kroků. Začínáme s předběžnou kalkulací, kde zjistíme, kolik si můžete dovolit půjčit, a pokračujeme přes výběr správného hypotečního produktu až po podpis smlouvy. Důležité je mít přehled o vašich finančních možnostech a potřebách. ",
    sTitle2: "JAKÉ JSOU KROKY K ZÍSKÁNÍ HYPOTÉKY?",
    description2:
      "Získání hypotéky je zásadním krokem na cestě k vlastnictví domu, který vyžaduje pečlivou přípravu a porozumění procesu. Začíná se přezkoumáním vaší finanční situace, včetně kreditního skóre, úspor, dluhů a příjmů, abyste měli jasnou představu o tom, kolik můžete realisticky do hypotéky investovat. Na základě této analýzy si stanovíte rozpočet, který určí maximální výši hypotéky, jež si můžete dovolit bez ohrožení vašeho finančního komfortu. Důležitým krokem je výběr vhodného hypotečního úvěru, který nejlépe odpovídá vašim potřebám a finanční situaci, přičemž je nutné porozumět různým dostupným možnostem, úrokovým sazbám a požadavkům. Po výběru vhodného úvěrového produktu následuje shromáždění a předložení všech potřebných dokumentů finanční instituci. Proces schválení, který může zahrnovat ověření vašich financí a hodnocení nemovitosti, může trvat několik týdnů. Nakonec, po schválení vaší žádosti, přichází uzavření hypotéky, což je poslední krok k zajištění financování vašeho nového domova. Celý proces vyžaduje pečlivou přípravu a pozornost k detailům, ale s pravými informacemi a přístupem se může zdát méně náročný.",
    sTitle3: "PROČ JE VÝHODNÉ SJEDNAT HYPOTÉKU SEMNOU?",
    description3:
      "Sjednat hypotéku se mnou znamená získat osobní přístup a odborné poradenství. Jsem zde, abych vás provedl celým procesem, pomohl vyhnout se běžným chybám a najít nejvýhodnější řešení přizpůsobené vašim potřebám. Díky spolupráci se mnou ušetříte čas i peníze a můžete být jisti, že dostanete nejlepší možnou hypotéku.",
    icon: "flaticon-bar-chart",

    serviceSteps: [
      {
        name: "1. Začínáme",
        icon: msImgS1,
        description:
          "Setkáme se podle vašich preferencí - osobně nebo prostřednictvím digitálních kanálů jako jsou telefon, GoogleMeet, Zoom, Skype.",
      },
      {
        name: "2. Prozkoumáme vaši situaci",
        icon: msImgS2,
        description:
          "Vycházejíc z toho, co potřebujete a jaké máte možnosti, navrhnu vám nejlepší postup, včetně mých osobních doporučení.",
      },
      {
        name: "3. Najdeme ideální řešení pro vaše vysněné bydlení",
        icon: msImgS3,
        description:
          "Společně vybereme řešení, které bude nejvíce vyhovovat vašim potřebám a představám, a pustíme se do díla.",
      },
      {
        name: "4. Začínáme s žádostí",
        icon: msImgS4,
        description:
          "Prvním krokem je zjistit vaše skóre u banky, což vám přinese lepší přehled a pocit jistoty.",
      },
      {
        name: "5. Cesta k hypotéce",
        icon: msImgS5,
        description:
          "Budu vám asistovat při vyplňování formulářů, zajištění ocenění a shromažďování potřebných dokumentů pro banku.",
      },
      {
        name: "6. Schválení a finalizace",
        icon: msImgS6,
        description:
          "Prohlédnu s vámi všechny smlouvy, probereme je a zajistím, aby byla hypotéka úspěšně podepsána.",
      },
      {
        name: "7. Bezproblémové čerpání",
        icon: msImgS7,
        description:
          "Udělám vše pro to, abyste mohli svou hypotéku čerpat snadno a bez zbytečných komplikací.",
      },
      {
        name: "8. Čerpáním to nekončí",
        icon: msImgS8,
        description:
          "I po čerpání úvěru je třeba splnit některé další podmínky. Nemusíte se obávat, i s tím vám rád pomohu.",
      },
      {
        name: "9. Bydlíte",
        icon: msImgS9,
        description: "",
      },
    ],
  },
  {
    Id: "2",
    image: sgImgS2,
    sTitle: "Povinné ručení",
    linkName: "povinne_ruceni",
    description:
      "Představte si, že každý den, když sednete za volant svého auta, máte jistotu a klid. Ne, že byste plánovali havarovat – ale pokud by se to náhodou stalo, víte, že máte záda pokrytá. Se správným povinným ručením se můžete soustředit na to, co je opravdu důležité – bezpečnou jízdu a užívání života bez zbytečných obav. Všichni chceme ušetřit, a také se všichni chceme cítit se opravdu bezpečně. Proto jsem zde, abychom vám pomohl s obojím.",
    sTitle2: "BEZSTAROSTNÝ PRŮVODNCE POVINNÝM RUČENÍM!",
    description2:
      "S povinným ručením ode mě se nemusíte bát nečekaných výdajů spojených s dopravními nehodami. Vaše auto bude v bezpečí a vaše finanční situace stabilní. Co víc si můžete přát? Proces sjednání je navíc snadný a rychlý. Už žádné komplikované formuláře či zdlouhavé čekání na schválení. A co je hlavní – já  se postarám o všechno za vás. Od výpovědi staré smlouvy až po hledání nejvýhodnější nabídky pro příští rok. Vy tak můžete mít hlavu v klidu a soustředit se na to, co je opravdu důležité.",
    sTitle3: "PROČ SEMNOU?",
    description3:
      "Využijte mých služeb a ujistěte se, že vaše jízda bude vždy bezpečná a bezstarostná. S mnou na vaší straně můžete očekávat jen to nejlepší.  Jste připraveni začít žít plnohodnotný život bez zbytečných starostí? Kontaktujte mě ještě dnes!",
    icon: "flaticon-verified",
    serviceSteps: [
      {
        name: "1. Úspora času a peněz",
        icon: csImgS1,
        description:
          "Najdu pro vás nejvýhodnější nabídku, aniž byste museli trávit hodiny hledáním na internetu.",
      },
      {
        name: "2. Klid a jistota",
        icon: csImgS2,
        description:
          "S povinným ručením ode mě se můžete cítit bezpečně na každé cestě, bez obav z neočekávaných výdajů.",
      },
      {
        name: "3. Snadné a rychlé sjednání",
        icon: csImgS3,
        description:
          "Veškerou administrativu vyřídím za vás, takže si můžete užívat víc času pro sebe a své blízké.",
      },
      {
        name: "4. Osobní přístup",
        icon: csImgS4,
        description:
          "Jsem tady pro vás, abychom odpovídali na vaše dotazy a řešili vaše požadavky. Spokojenost mých klientů je můj hlavní cíl.",
      },
      {
        name: "5. Bezstarostnost",
        icon: csImgS5,
        description:
          "Nemusíte se už stresovat sledováním dat končících smluv. Připomenu vám, kdy je čas na obnovu a najdu pro vás tu nejlepší možnou nabídku.",
      },
    ],
  },
  {
    Id: "3",
    image: sgImgS3,
    sTitle: "Pojištění",
    linkName: "pojisteni",
    description:
      "Pojištění je nezbytné pro ochranu vašeho zdraví, majetku a finančního well-being. V nepředvídatelném světě poskytuje důležitou síť bezpečí, která vám umožní žít s menšími obavami o budoucnost. Já jsem tu proto abych Vám tento problém dokázal rychle a efektivně zanalyzovat jaké pojištění potřebujete a najít pro vás tu nejvhodnější variantu na trhu",
    sTitle2: "ŽIVOTNÍ POJIŠTĚNÍ",
    description2:
      "V dnešním neustále se měnícím a nepředvídatelném světě představuje pojištění klíčový prvek ochrany pro jednotlivce, rodiny i podniky. Mít pojištění znamená nejen získat finanční zabezpečení proti nepředvídaným událostem a katastrofám, ale také si zajistit klid a jistotu v každodenním životě. Níže jsou uvedeny hlavní důvody, proč je pojištění tak důležité.",
    sTitle3: "POJIŠTĚNÍ ODPOVĚDNOSTI",
    description3:
      "Představte si klidný den, který se však nečekaně změní v chaotický moment nevědomosti. Možná to bylo jen malé štěně, které se rozhodlo dát si sprint přímo přes cizí záhonek, nebo vaše děti, co si hrály na detektivy a omylem rozbily sousedův plot. V těchto momentech se nemusí jednat pouze o drobné nepříjemnosti, ale o situace, které mohou vyústit ve finanční zátěž pro vás. Zde přichází na scénu pojištění odpovědnosti. Poskytne Vám klid a ochranu před nepředvídatelnými událostmi. Víme, že život je plný překvapení a i ta nejmenší nepozornost může mít velké důsledky. Naše služba zprostředkování pojištění odpovědnosti vám zajistí ochranu, která vám umožní žít váš život naplno, bez obav z náhodných událostí, které by vás mohly finančně zatížit.",
    sTitle4: "POJIŠTĚNÍ MAJETKU",
    description4:
      "Pojistit svůj domov je jedním z nejdůležitějších kroků, které můžete pro sebe a svou rodinu udělat. Představte si, že se jednoho dne probudíte a zjistíte, že kvůli nepředvídatelnému neštěstí přicházíte o vše, na čem vám záleží. Nepříjemná myšlenka, že? Nemusí to tak ale být. Je důležité vybrat správné pojištění, díky kterému se můžete cítit bezpečně, vědomi si, že vaše střecha nad hlavou je v dobrých rukou. O podpojištěnosti už jste určitě slyšeli. Je to situace, kdy v případě nehody zjistíte, že vaše pojistka nepokrývá skutečnou hodnotu vašeho majetku. To může vést k zásadním finančním ztrátám, které by mohly mít katastrofální následky.  Se mnou se toho ale obávat nemusíte. Díky pravidelnému servisu a pečlivé analýze vašich potřeb vám zajistím, že podpojištěnost pro vás nebude problém.",
    sTitle5: "CESTOVNÍ POJIŠTĚNÍ",
    description5:
      "Představte si pocit naprostého klidu a bezpečí, ať už se vydáte na libovolnou cestu po světě. Ať je to pracovní meeting v Paříži, rodinná dovolená v Chorvatsku nebo dobrodružná expedice na Madeiru. S cestovním pojištěním vás nic nezaskočí. Nejenže se postaráme o to, aby vaše zážitky z cest zůstaly nezapomenutelné z těch správných důvodů, ale také zaručím, že jakékoli nepříjemnosti budou vyřešeny rychle a efektivně. Nikdy nevíte, kdy se vám může přihodit něco nečekaného. Ať už je to náhlé onemocnění, úraz, ztráta zavazadel, nebo nechtěné způsobení škody třetí osobě, je klíčové být na tato rizika připraven. Vím, z vlastních zkušeností, že každý cestovatel a každá cesta jsou unikátní, a proto přistupuju ke každému klientovi individuálně, abychom našel ideální pojištění přesně podle vašich potřeb za optimální cenu.",
    icon: "flaticon-add",
    serviceSteps: [
      {
        name: "1. Finanční ochrana",
        icon: isImgS1,
        description:
          "Pojištění poskytuje finanční ochranu proti řadě rizik - od osobních přes majetkové až po profesionální. V případě nehody, nemoci, poškození majetku nebo jiné nepříznivé události pojištění pokrývá náklady a snižuje finanční zátěž, kterou byste jinak museli nést sami.",
      },
      {
        name: "2. Mír a klid",
        icon: isImgS2,
        description:
          "Vědomí, že máte pojištění, přináší duševní pohodu. V případě nějakého neštěstí nebo škody víte, že nebudete muset řešit finanční následky sami. Tento klid může významně přispět k vaší celkové životní spokojenosti.",
      },
      {
        name: "3. Ochrana rodiny a majetku",
        icon: isImgS3,
        description:
          "Pojištění života a pojištění nemovitostí chrání vaši rodinu a váš domov před finančními dopady nepředvídaných událostí. V případě vážné nemoci, úmrtí nebo ztráty domova pojištění zajistí, že vaše rodina bude mít finanční prostředky potřebné k pokračování v běžném životě.",
      },
      {
        name: "4. Podpora v případě zdravotních problémů",
        icon: isImgS4,
        description:
          "Zdravotní pojištění je nepostradatelné pro pokrytí nákladů na lékařskou péči, operace, léky a hospitalizaci. V zemích, kde zdravotní péče vyžaduje vysoké výdaje z vlastní kapsy, může být pojištění rozhodující pro získání potřebné péče bez zbytečného finančního zatížení.",
      },
      {
        name: "5. Zajištění odpovědnosti",
        icon: isImgS5,
        description:
          "Pojištění odpovědnosti chrání v případě, že vaše činy nebo vaše majetek způsobí škodu jiným osobám. To je nezbytné nejen pro podnikatele, ale i pro jednotlivce, kteří se mohou ocitnout v situaci, kdy musí nést náklady na nápravu způsobené škody.",
      },
      {
        name: "6. Podpora dlouhodobých cílů",
        icon: isImgS6,
        description:
          "Některé druhy pojištění, jako je životní pojištění, mohou sloužit také jako investiční nástroj a pomoci vám šetřit na budoucí výdaje, jako je vzdělání dětí nebo důchod.",
      },
    ],
  },
  {
    Id: "4",
    sTitle: "Další služby",
    image: sgImgS4,
    linkName: "dalsi",
    description:
      "Jak získat hypotéku? Sjednání hypotéky může být zdánlivě komplikovaný proces, avšak ve skutečnosti je to o řádění několika klíčových kroků. Začínáme s předběžnou kalkulací, kde zjistíme, kolik si můžete dovolit půjčit, a pokračujeme přes výběr správného hypotečního produktu až po podpis smlouvy. Důležité je mít přehled o vašich finančních možnostech a potřebách. ",
    sTitle2: "JAKÉ JSOU KROKY K ZÍSKÁNÍ HYPOTÉKY?",
    description2: "",
    sTitle3: "",
    description3: "",
    icon: "flaticon-smartphone",
    sTitle2: "",
  },
];

export default Services;
